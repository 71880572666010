<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card>
      <detail-list title="公司信息" style="width: 100%">
        <detail-list-item term="公司名稱" style="width: 100%">{{ form.companyName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="统一社会信用码" style="width: 100%">{{ form.socialCredit }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="法定经营范围" style="width: 100%">{{ form.businessScope }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="公司-国家" style="width: 100%">{{ form.companyCountry }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="公司-地址省" style="width: 100%">{{ form.companyProvinceName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="公司-地址市" style="width: 100%">{{ form.companyCityName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="公司-地址区" style="width: 100%">{{ form.companyCountyName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="公司-详细地址" style="width: 100%">{{ form.companyDetailedAddress }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
      </detail-list>
      <a-divider style="margin-bottom: 32px"/>
      <detail-list title="法人信息" style="width: 100%">
        <detail-list-item term="姓名" style="width: 100%">{{ form.legalRealName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="联系方式" style="width: 100%">{{ form.legalMobile }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="身份证" style="width: 100%">{{ form.legalCard }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="身份证-人像面" style="width: 100%">
          <img style="width: 100px;height: 60px;" preview="人像面" :src="form.portraitPlane" v-if="form.portraitPlane != null && form.portraitPlane != ''">
        </detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="身份证-国徽面" style="width: 100%">
          <img style="width: 100px;height: 60px;" preview="国徽面" :src="form.emblemPlane" v-if="form.emblemPlane != null && form.emblemPlane != ''">
        </detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="营业执照" style="width: 100%">
          <img style="width: 100px;height: 60px;" preview="营业执照" :src="form.businessLicense" v-if="form.businessLicense != null && form.businessLicense != ''">
        </detail-list-item>
      </detail-list>
      <a-divider style="margin-bottom: 32px"/>
      <detail-list title="店铺信息" style="width: 100%">
        <detail-list-item term="店铺-国家" style="width: 100%">{{ form.storeCountry }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-地址省" style="width: 100%">{{ form.storeProvinceName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-地址市" style="width: 100%">{{ form.storeCityName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-地址区" style="width: 100%">{{ form.storeCountyName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-详细地址" style="width: 100%">{{ form.storeDetailedAddress }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-简介" style="width: 100%">{{ form.storeDetail }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="营业時間" style="width: 100%">{{ form.openStartTime }} ~ {{ form.openEndTime }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-客服电话" style="width: 100%">{{ form.storeCall }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-负责人" style="width: 100%">{{ form.storePrincipal }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-负责人电话" style="width: 100%">{{ form.principalCall }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="店铺-门头照" v-if="form.doorPicture != null && form.doorPicture != ''" style="width: 100%">
          <img style="width: 100px;height: 60px;" v-for="(rec, index) in form.doorPicture.split(',')" preview="店铺-门头照" :src="rec">
        </detail-list-item>
      </detail-list>

    </a-card>

  </a-drawer>
</template>

<script>
import { getStoreCertification } from '@/api/platform/storeCertification'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item;

export default {
  name: 'CreateForm',
  props: {},
  components: {
    DetailList,
    DetailListItem
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        merchantType: null,

        certificationType: null,

        legalRealName: null,

        legalMobile: null,

        legalCard: null,

        portraitPlane: null,

        emblemPlane: null,

        businessLicense: null,

        companyName: null,

        socialCredit: null,

        businessScope: null,

        userId: null,

        applicationStatus: 0,

        refusalCause: null,

        storeName: null,

        storeLogo: null,

        storeBackground: null,

        typeId: null,

        classId: null,

        integralBail: null,

        integralServe: null,

        storeCountry: null,

        storeProvinceName: null,

        storeCityName: null,

        storeCountyName: null,

        storeDetailedAddress: null,

        storeLongitude: null,

        storeDimensionality: null,

        companyCountry: null,

        companyProvinceName: null,

        companyCityName: null,

        companyCountyName: null,

        companyDetailedAddress: null,

        companyLongitude: null,

        companyDimensionality: null,

        companyAddress: null,

        storeDetail: null,

        openStartTime: null,

        openEndTime: null,

        storeCall: null,

        storePrincipal: null,

        principalCall: null,

        doorPicture: null,

        starLevel: null,

        createTime: null,

        remark: null,

        collectionNum: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        merchantType: null,
        certificationType: null,
        legalRealName: null,
        legalMobile: null,
        legalCard: null,
        portraitPlane: null,
        emblemPlane: null,
        businessLicense: null,
        companyName: null,
        socialCredit: null,
        businessScope: null,
        userId: null,
        applicationStatus: 0,
        refusalCause: null,
        storeName: null,
        storeLogo: null,
        storeBackground: null,
        typeId: null,
        classId: null,
        integralBail: null,
        integralServe: null,
        storeCountry: null,
        storeProvinceName: null,
        storeCityName: null,
        storeCountyName: null,
        storeDetailedAddress: null,
        storeLongitude: null,
        storeDimensionality: null,
        companyCountry: null,
        companyProvinceName: null,
        companyCityName: null,
        companyCountyName: null,
        companyDetailedAddress: null,
        companyLongitude: null,
        companyDimensionality: null,
        companyAddress: null,
        storeDetail: null,
        openStartTime: null,
        openEndTime: null,
        storeCall: null,
        storePrincipal: null,
        principalCall: null,
        doorPicture: null,
        starLevel: null,
        createTime: null,
        remark: null,
        collectionNum: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStoreCertification({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '设置星级'
      })
    },
  }
}
</script>
<style>
.ant-divider-horizontal {
  display: none;
}
</style>
