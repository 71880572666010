import request from '@/utils/request'


// 查询店铺-商家认证列表
export function listStoreCertification(query) {
  return request({
    url: '/platform/store-certification/list',
    method: 'get',
    params: query
  })
}

// 查询店铺-商家认证分页
export function pageStoreCertification(query) {
  return request({
    url: '/platform/store-certification/page',
    method: 'get',
    params: query
  })
}

// 查询店铺-商家认证详细
export function getStoreCertification(data) {
  return request({
    url: '/platform/store-certification/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺-商家认证
export function addStoreCertification(data) {
  return request({
    url: '/platform/store-certification/add',
    method: 'post',
    data: data
  })
}

// 修改店铺-商家认证
export function updateStoreCertification(data) {
  return request({
    url: '/platform/store-certification/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺-商家认证
export function delStoreCertification(data) {
  return request({
    url: '/platform/store-certification/delete',
    method: 'post',
    data: data
  })
}
